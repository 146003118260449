<script>
export default {
  name: "search-results",
  props: ["query", "collections"],
  methods: {
    isDefined(array) {
      if (array == undefined) {
        return false;
      } else if (array.length > 0) {
        return true;
      }
    },
    catchSlugs(array) {
      return array.map(a => a.slug);
    }
  }
};
</script>

<template>
  <div class="hero-search-result" v-if="query">
    <div class="hero-search-result__container" style="background:white;">
      <header v-if="true" class="hero-search-result__header">Searching for "{{ query }}"</header>
      <main class="hero-search-result__content">

        <div class="divider-label" v-if="isDefined(collections.art_types)">
          Art types
          <div class="divider"></div>
        </div>
        <!-- by_art_type
        by_event_type-->
        <a :href="`/discover/${collections.currentUrlLocation}/${typer.slug}`"
          :key="typer.slug"
          class="hero-search-result__user"
          v-for="typer in collections.art_types"
        >
          <img
            class="hero-search-result__user-icon"
            :src="typer.icon.medium ? typer.icon.medium : typer.icon.small"
          >
          <h5 class="hero-search-result__user-name">{{ typer.name }}</h5>
        </a>

        <div class="divider-label" v-if="isDefined(collections.specialities) || isDefined(collections.specialities_group)">
          Specialities
          <div class="divider"></div>
        </div>

        <a :href="`/discover/${collections.currentUrlLocation}/${catchSlugs(item.art_types)}/${item.slug}`"
          :key="item.slug + item.name"
          class="hero-search-result__user"
          v-for="item in collections.specialities_group"
        >
          <img class="hero-search-result__user-icon" src="../../images/arttype-group-placeholder.svg">
          <h5 class="hero-search-result__user-name">
            {{ item.name }}
            <span>
              in
              <li v-for="artType in item.art_types" :key="artType.slug + artType.name">{{artType.name}}</li>
            </span>
          </h5>
        </a>

        <a :href="`/discover/${collections.currentUrlLocation}/${special.art_type.slug}/${special.slug}`"
          :key="special.art_type.slug + special.name"
          class="hero-search-result__user"
          v-for="special in collections.specialities"
        >
          <img
            class="hero-search-result__user-icon"
            :src="special.art_type
                      ? special.art_type.icon.medium
                        ? special.art_type.icon.medium
                        : special.art_type.icon.small
                      : null"
          >
          <h5 class="hero-search-result__user-name">
            {{ special.name }}
            <span>in {{special.art_type.name ? special.art_type.name : ''}}</span>
          </h5>
        </a>

        <div class="divider-label" v-if="isDefined(collections.genres) || isDefined(collections.genres_group)">
          Genres
          <div class="divider"></div>
        </div>

        <a :href="`/discover/${collections.currentUrlLocation}/${catchSlugs(item.art_types)}/${item.slug}`"
          :key="item.slug + item.name"
          class="hero-search-result__user"
          v-for="item in collections.genres_group"
        >
          <img class="hero-search-result__user-icon" src="../..//images/arttype-group-placeholder.svg">
          <h5 class="hero-search-result__user-name">
            {{ item.name }}
            <span>
              in
              <li v-for="artType in item.art_types" :key="artType.slug + artType.name">{{artType.name}}</li>
            </span>
          </h5>
        </a>

        <a :href="`/discover/${collections.currentUrlLocation}/${genre.art_type.slug}/${genre.slug}`"
          :key="genre.art_type.slug + genre.slug"
          class="hero-search-result__user"
          v-for="genre in collections.genres"
        >
          <img
            class="hero-search-result__user-icon"
            :src="genre.art_type
                      ? genre.art_type.icon.medium
                        ? genre.art_type.icon.medium
                        : genre.art_type.icon.small
                      : null"
          >
          <h5 class="hero-search-result__user-name">
            {{ genre.name }}
            <span>in {{genre.art_type.name ? genre.art_type.name : ''}}</span>
          </h5>
        </a>

        <div class="divider-label" v-if="isDefined(collections.artists_in_location)">
          Artists in your location
          <div class="divider"></div>
        </div>

        <a :href="'/artist/' + artist.slug"
          :key="artist.slug"
          class="hero-search-result__user"
          v-for="artist in collections.artists_in_location"
        >
          <img
            class="hero-search-result__user-avatar"
            :src="artist.avatar ? artist.avatar.small : null"
          >
          <div>
            <h5
              class="hero-search-result__user-name"
            >{{ artist.stage_name ? artist.stage_name : artist.full_name }}</h5>
            <span class="small cr-silver">
              {{ artist.location ? artist.location.name : null}}
              {{ artist.art_type ? ' • ' + artist.art_type.name : '' }}
            </span>
          </div>
        </a>

        <div class="divider-label" v-if="isDefined(collections.other_artists)">
          Other artists
          <div class="divider"></div>
        </div>

        <a :href="'/artist/' + artist.slug"
          :key="artist.slug"
          class="hero-search-result__user"
          v-for="artist in collections.other_artists"
        >
          <img
            class="hero-search-result__user-avatar"
            :src="artist.avatar?artist.avatar.small:null "
          >
          <div>
            <h5
              class="hero-search-result__user-name"
            >{{ artist.stage_name ? artist.stage_name : artist.full_name }}</h5>
            <span class="small cr-silver">
              {{ artist.location ? artist.location.name : null}}
              {{ artist.art_type ? ' • ' + artist.art_type.name : '' }}
            </span>
          </div>
        </a>
      </main>
    </div>
  </div>
</template>

<style scoped>
.divider-label {
  color: #252c3f;

  padding: 8px 16px;
  font-size: 14px;
  margin: auto;
}
.divider-label > .divider {
  margin: 0px;
  margin-top: 5px;
}
</style>
