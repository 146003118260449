<script>
import SearchResults from "./SearchResults.vue"

export default {
  props: ['location', "currentLocation"],
  components: {
    SearchResults
  },
  data: function () {
    return {
      collections: {
        art_types: [],
        artists_in_location: [],
        other_artists: [],
        specialities: [],
        currentUrlLocation: this.currentLocation
      },
      currentSearchLocation: this.currentLocation,
      cities: [],
      query: ''
    }
  },
  methods:{
    find(event){
      this.query = event.target.value || ''
      const new_query = (event.target.value || '').trim()

      if (new_query) {
        const params = {
          location: this.location,
          query: new_query
        }

        this.$axios.get('search/suggestions', {params: params})
          .then(response => {
            let findCity = this.query.length == 1 ? true : false

            if(findCity && window.location.pathname.includes("/discover")) {
              let filterCity = ''
              let location = localStorage.getItem('location_country')
              this.$axios.get('/cities', {
                params: {
                  country_code: location,
                }
              })
              .then(cityResponse => {
                this.cities = cityResponse.data
                const city = this.cities.find(city => city.name.toLowerCase() === this.currentLocation);
                filterCity = city ? this.generateShortForm(city.name).toLowerCase() : '';
                this.collections = response.data
                this.collections.currentUrlLocation = filterCity ? filterCity : localStorage.getItem('short_form', '');
                this.currentSearchLocation = this.collections.currentUrlLocation
                findCity = false
              })
            } else {
              this.collections = response.data
              this.collections.currentUrlLocation = this.currentSearchLocation
            }
          })
      }
    },
    generateShortForm(name) {
      return name.replace(/\s+/g, '')
    },
    closeSearch(){
      this.query = ''
      setTimeout(() => {
        this.searchModal = false
      }, 100)
    }
  },
  beforeDestroy(){
    document.querySelector('body').style = null
  }

}
</script>

<template>
  <div class="search">
    <input
    :value="query"
     class="search__input"
     style = "width:100%"
     :placeholder="'Search'"
     type="text"
     @input="find"
    />
    <img
      v-if="query"
      @click="closeSearch"
      src="../../images/close-orange.svg"
      class="search__close-search"
    >
    <search-results
      :collections="collections"
      :query="query"
    />
  </div>
</template>
