import Vue from 'vue'
import axios from 'axios'

// import TurbolinksAdapter from 'vue-turbolinks'
import NavSearch from '../vue/search/NavSearch.vue'

// Vue.use(TurbolinksAdapter)

document.addEventListener('DOMContentLoaded', () => {
  let el = document.getElementById('vue-nav-search')

  if (el) {
    Vue.prototype.$axios = axios
    let token = localStorage.getItem('token')
    if (token === null) {
      token = el.dataset.authToken
      localStorage.setItem('token', el.dataset.authToken)
    }
    Vue.prototype.$axios.defaults.baseURL = '/v3'

    Vue.prototype.$axios.defaults.headers.common = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }

    const props = {
      location: el.dataset.location,
      currentLocation: el.dataset.currentLocation
    }

    new Vue({
      el: el,
      render: h => h(NavSearch, { props })
    })
  }
})
